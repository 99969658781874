.experience__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
}

.experience__container > div {
    background: var(--color-bg-variant);
    padding: 2rem 3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: transform 0.5s ease, opacity 0.5s ease;
}

.experience__container > div:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
    transform: scale(1.1);
    opacity: 0.8;
}

.experience__container > div h3{
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-primary); 
}

.experience__content{
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
    column-gap: 0.5rem;

}

.experience__details {
    gap: 0.5rem;
    text-align: center;
}

.experience__details-icon {
    margin-top: 4px;
    color: var(--color-primary-variant);
}

/* ============= MEDIA QUERIES (MEDIUM DEVICES (Tablets)) =============*/

@media screen and (max-width: 1024px){
    .experience__container {
        grid-template-columns: repeat(1, 1fr);
    }
}


/* ============= MEDIA QUERIES (SMALL DEVICES (Phones)) =============*/

@media screen and (max-width: 600px) {
    .experience__container {
        gap: 1rem;
        padding: 0rem;
    }
    
    .experience__container > div {
        padding: 1rem;
    }
    
    .experience__content{
        row-gap: 1.5rem;
    }

    .experience__details h4 {
        font-size: 0.9rem
    }

}

